@tailwind components;
@tailwind base;
@tailwind utilities;
 

/*  
nav{
  a{
    color: #f1f1f1 !important;
  }
}.dropdown-link {
  color: #6e1b2a;
  text-decoration: none;
  display: block;
  padding: 10px 20px;
}

.dropdown-link:hover {
  background: #f1f1f1;
  color: #6e1b2a;
}

.mui-menu {
  background-color: #6e1b2a !important;
  color: white !important;
}

@media (max-width: 1024px) {
  .dropdown-link {
      color: white;
  }

  .mui-menu {
      background-color: white !important;
      color: #6e1b2a !important;
  }
} */


@media (max-width:1100px) {
  .hero-text{
    font-size: 1.6rem !important;
  }

}

@media (min-width:900px) {
  .hero-img{
    height: 75vh;
  }
}
