.showNav {
    opacity: 1;
    transform: translateX(0);
    transition: all .3s linear;
    z-index: 999;
}

.hideNav {
    opacity: 0;
    transform: translateX(-100%);
    transition: all .3s linear;
}

.nav-link {
    border-top: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    padding: 10px 0 0 7px;
    transition: all .2s linear;
    /* opacity: 0; */
}


.secondary {
    position: fixed;
    transform: translateY(-100%);
    transition: all .2s linear;
    opacity: 1;
    background-color: #fff;
    top: 0;
    width: 100%;
}

.showThis {
    transform: translateY(0);
}

.nav-link-main {
    color: #6e1b2a;
    font-weight: 500;
    font-size: 1rem;

}


#partners {


    h1,
    h2,
    h3,
    h4,
    h5,
    h6 ,.heading {
        color: #6e1b2a;
        font-weight: 600;
    }
}



.showDropdown{
    visibility: visible;
    z-index: 900;
    opacity: 1;
    transition: all .1s linear;
}

.hideDropdown{
    transition: all .1s linear;
    visibility: hidden;
    z-index: -900;
    opacity: 0;
}




.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.overlay {
    background: rgba(0, 0, 0, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal-title {
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 24px;
    color: #6e1b2a;
}

.modal-body {
    margin-bottom: 20px;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
}
